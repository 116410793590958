body {
  background-color: #1c1c1c !important;
}

hr {
  width: 60%;
  border-top: 1px solid #ccc !important;
  filter: drop-shadow(8px 8px 1px rgb(0, 0, 0));
}

.header {
  background: #1c1c1c;
}

.svgShadow {
  filter: drop-shadow(10px 10px 1px rgba(0, 0, 0, 0.8));
  margin-right: 10px;
}

.movieShadow {
  filter: drop-shadow(15px 10px 1px rgba(0, 0, 0, 0.8));
}

.textShadow {
  text-shadow: 10px 10px 2px rgba(0, 0, 0, 0.8);
}

.textShadowSmall {
  text-shadow: 2px 2px 10px rgb(0, 0, 0);
}

.blackText {
  color: black;
}

.whiteText {
  text-align: center;
  color: white;
}

.grayText {
  font-size: small;
  color: grey;
}

.title {
  font-size: 2em;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  margin-right: 10px;
}

.hero {
  width: 100%;
  height: 600px;
  position: relative;
}

.carousel-item > img {
  width: 100%;
  height: 600px;
  object-fit: cover;
  position: relative;
}

.searchBar {
  width: 60%;
  margin: auto;
  padding: 30px;
  /* position: absolute;
    z-index: 1;
    top: 5%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%); */
}

.input-group-lg > .form-control {
  height: 70px !important;
}

.cardDeck {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)) !important;
}

.cardDeck-rec {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)) !important;
}

.cardImage {
  height: 250px;
  object-fit: cover;
  position: relative;
}


.cardImage-rec {
  height: 150px;
  object-fit: cover;
  position: relative;
}

.card-body {
  height: 200px;
}

.card-text {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-title-rec {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-text-rec {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.spinner {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.details-text {
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  margin: auto;
  margin-top: 100px !important;
  margin-bottom: 20px !important;
  border-radius: 20px;
  padding: 20px;
  width: 80%;
}

.details-backdrop {
  z-index: -1;
  position: absolute;
  top: 0;
  width: 100%;
  filter: blur(50px);
  -webkit-filter: blur(50px);
}

.Disqus {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 20px;
  padding: 20px;
}

.media-image {
  position: relative;
  width: 200px;
  top: -50px;
}

@media only screen and (max-width: 600px) {
  .media-image {
    display: none;
  }

  .searchBar {
    width: 100%;
    padding-right: 5px;
    padding-left: 5px;
  }
}

@media only screen and (max-width: 400px) {
  .cardDeck {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
  }
}
